.App {
  margin-top: 10px;
}

header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 10px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
}

.dateTemp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 86vw;
    margin: 4px 4vw 0 4vw;
    /* margin-top: 4px; */
    padding: 0.5vw 3vw;
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    background-color: #721010;
    border-radius: 0.5rem;
    /* box-shadow: 0 0 0.5rem rgba(232, 70, 70, 0.5); */
    @media (min-width: 768px) {
      width: 50%;
      margin: 0 auto;
      margin-top: 4px;
      font-size: 35px;
    }
}

.coordinates {
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.m7Minus {
  background-color: #8c9793;
  color: white;
}

.m6Tom1 {
  background-color: #1a222d;
  color: white;
}

.zeroTo5 {
  background-color: #7c4f6e;
  color: white;
}

.sixTo12 {
  background-color: #53496b;
  color: white;
}

.thirteenTo19 {
  background-color: #ea6953;
  color: white;
}

.twentyTo28 {
  background-color: #e87100;
  color: white;
}

.twentyeightPlus {
  background-color: #910e16;
  color: white;
}
